<template>
  <div class="mobile-page">
    <mainChat></mainChat>
  </div>
</template>

<script>
import mainChat from "@/views/main/index.vue";

export default {
  components: {
    mainChat,
  },
};
</script>

<style>
.mobile-page{
  width: 100%;
  height: 100%;
}
</style>